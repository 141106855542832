.createLineupPage {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	margin-bottom: 80px;

	.header {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-shadow: var(--box-shadow-2);

		h2 {
			padding-top: 0.8rem;
			padding-bottom: 0.8rem;
			font-size: 1.8rem;
		}

		.header-inner {
			display: flex;
			flex-direction: column;
			width: 95%;
			max-width: 1200px;

			.header-options {
				display: flex;
				flex-direction: row;

				.header-options-btn {
					border: None;
					font-weight: 600;
					color: var(--btn-blue);
					cursor: pointer;
					background-color: white;
					padding: 0.9rem;
					border-bottom: 2px solid transparent;
					margin-right: 0.6rem;
					font-size: 0.9rem;
				}

				.header-options-btn-red {
					color: red;
				}

				.header-options-btn:hover {
					border-bottom: 2px solid var(--btn-blue);
				}

				.header-options-btn-red:hover {
					border-bottom: 2px solid red;
				}
			}
		}

		.header-lower {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.header-details {
			display: flex;
			flex-direction: row;

			.info-block {
				margin-right: 2rem;

				.label,
				.link {
					margin-top: 0;
					margin-bottom: 0;
					color: var(--grey-9);
					background: None;
					border: None;
					font-size: 0.8rem;
				}

				.link {
					color: blue;
					font-weight: 600;
					cursor: pointer;
				}

				.value {
					font-weight: 500;
					font-size: 0.9rem;
				}

				.tag-wrapper {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					max-width: 400px;
					height: fit-content;

					.tag {
						width: fit-content;
						border: 1px solid var(--grey-3);
						padding: 0.2rem 0.5rem 0.2rem 0.5rem;
						margin: 1px;
						font-size: 0.8rem;
						border-radius: 2px;
					}
				}
			}
		}
	}

	.loading-wrapper {
		width: 100%;
		min-height: 50vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.games-outer {
		display: flex;
		flex-direction: row;
		margin-bottom: 0.4rem;
		overflow-x: auto;

		.games-inner {
			display: flex;
			flex-direction: row;

			.game {
				background-color: white;
				border: 1px solid var(--grey-5);
				border-radius: 5px;
				margin: 0.2rem;
				cursor: pointer;
				padding: 0.5rem 0.9rem 0.5rem 0.9rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				outline: 1px solid transparent;

				p {
					margin: 0;
					padding: 0;
					font-weight: 500;
					font-size: 0.7rem;
				}
			}

			.selected {
				outline: 2px solid var(--btn-blue);
				border: 1px solid transparent;
			}

			.all {
				align-items: center;
				margin-left: 2px;
			}

			:hover {
				background-color: var(--grey-2);
				transition: background-color 0.2s ease-out;
			}
		}
	}

	.createLineupPage-inner {
		display: flex;
		width: 100%;
		max-width: 1400px;
		margin-left: auto;
		margin-right: auto;
		flex-direction: row;
		justify-content: space-around;
		flex-wrap: wrap;

		.lineup-outer {
			margin-left: 0.7rem;
			margin-right: 0.7rem;
			margin-top: 1rem;
			flex: 1 350px;

			.title {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				h3 {
					text-align: left;
					margin-left: 0.5rem;
					font-size: 1.5rem;
				}
			}
		}

		.players-table-wrapper {
			flex: 3 1 500px;
			margin-right: 0.7rem;
			margin-left: 0.7rem;
			margin-top: 1rem;
			max-width: 900px;
			min-width: 300px;
			margin-bottom: 1rem;

			.underline-btn-wrapper {
				width: 100%;
				justify-content: left;
				display: flex;
				flex-direction: row;
				align-items: center;
				max-width: 1100px;
				margin-bottom: 10px;

				.length-indicator {
					border: 1px solid var(--blue-grey-8);
					color: var(--blue-grey-9);
					padding: 0.2rem 0.4rem 0.2rem 0.4rem;
					border-radius: 100px;
					margin-left: 0.3rem;
					font-size: 0.7rem;
				}

				.underline-btn {
					border: None;
					background: none;
					font-size: 1.1rem;
					color: black;
					padding: 0.3rem 1rem 0.3rem 1rem;
					border-bottom: 0.2rem solid transparent;
					transition: all 0.2s;
					display: flex;
					flex-direction: row;
					align-items: center;
					cursor: pointer;
				}

				.underline-btn:hover:not(.active) {
					border-bottom: 0.2rem solid var(--grey-5);
				}

				.active {
					border-bottom: 0.2rem solid var(--dark-blue);
				}
			}

			.players-table-header {
				width: 100%;
				flex-direction: column;
				align-items: center;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				flex-wrap: wrap-reverse;


				.player-search {
					display: flex;
					flex-direction: row;
					border-radius: 3px;
					margin-bottom: 0.4rem;
					padding: 0.5rem 1rem 0.5rem 1rem;
					border: 1px solid var(--grey-5);
					outline: none;
					border-radius: 6px;
					background-color: white;

					.search-input {
						border: none;
						padding-left: 0.5rem;
						outline: none;
						background: none;
					}
				}

				.pos-filter-wrapper {
					margin-bottom: 0.4rem;
				}

				.filter-btn {
					cursor: pointer;
				}

				.filter-btn,
				.filter-btn-active {
					padding: 0.5rem 1rem 0.5rem 1rem;
				}
			}
		}
	}

	.fixed-bottom-footer {
		position: fixed;
		bottom: 0;
		display: flex;
		justify-content: center;
		width: 100%;
		background-color: white;
		box-shadow: var(--box-shadow-1);

		.fixed-bottom-footer-inner {
			max-width: 1200px;
			width: 95%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.footer-details {
			display: flex;
			flex-direction: row;
			align-self: center;

			.info-block {
				margin-right: 2rem;

				.value {
					font-weight: 500;
					font-size: 1rem;
				}

				.label {
					margin-top: 0;
					margin-bottom: 0;
					color: var(--grey-9);
					background: None;
					border: None;
					font-size: 0.8rem;
				}
			}
		}

		.lineup-btns {
			display: flex;
			flex-direction: row;
			display: flex;
			padding-top: 0.6rem;
			padding-bottom: 0.6rem;
			margin-right: -0.6rem;

			.clear-btn {
				color: red;
				border: none;
				background: none;
				width: fit-content;
				float: right;
				border: 1px solid red;
			}

			button {
				border-radius: 6px;
				padding: 0.4rem 1.2rem 0.4rem 1.2rem;
				margin-right: 0.6rem;
				cursor: pointer;
				display: flex;
				align-items: center;

				.icon {
					margin-left: 0.3rem;
				}
			}

			.revert-btn {
				color: var(--btn-blue);
				border: None;
				background: None;
				border: 1px solid var(--btn-blue);
			}

			.save-btn {
				background-color: #ffdb2b;
				font-weight: 500;
				border: None;
			}

			.inactive {
				background-color: var(--grey-4);
				color: black;
				border-color: black;
				cursor: default;
			}

			.save-btn:hover {
				background-color: #ffcc00;
			}

			.inactive:hover {
				background-color: var(--grey-4);
			}
		}
	}
}
