.lineup-player-mini {
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid var(--grey-2);
    border-left: 1px solid var(--grey-2);
    border-right: 1px solid var(--grey-2);
    height: 35px;
    .icon-wrapper {
        height: 100%;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
            cursor: pointer;
        }
        .delete-icon {
            color: red;
            font-size: 10pt;
        }
        .swap-icon {
            color: var(--btn-blue);
            font-size: 15pt;
        }
    }
    p {
        font-size: 9pt;
    }
    .position {
        flex: 1;
        display: flex;
        padding-left: 5px;
        margin-top: auto;
        margin-bottom: auto;
    }
    .player-name {
        flex: 4;
        display: flex;
        font-weight: 600;
        margin-left: 10px;
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
        color: blue;
        cursor: pointer;
    }
    .salary {
        flex: 1;
        margin-right: 5px;
        margin-top: auto;
        margin-bottom: auto;
    }
}