.upper-nav {
    display: flex;
    justify-content: center;
    background-color: var(--dark-blue);
    min-height: 50px;

    .logo {
        height: 1.2rem;
        cursor: pointer;
        padding: 0;
    }

    .dropdown {
        position: relative;
        display: inline-block;

        .hidden {
            display: none;
        }

        .dropdown-content {
            display: block;
            position: absolute;
            right: 0;
            background-color: var(--grey-2);
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            border-radius: 6px;

            :first-child {
                border-radius: 6px 6px 0px 0px;
            }

            :last-child {
                border-radius: 0px 0px 6px 6px;
            }

            a {
                color: black;
                padding: 10px 12px;
                text-decoration: none;
                display: block;
                font-size: 11pt;
            }

            a:hover {
                background-color: var(--grey-4);
            }
        }

        .dropbtn {
            padding: 6px;
            border: 2px solid white;
            background: none;
            border-radius: 50%;
            color: white;
            cursor: pointer;
        }
    }

    .upper-nav-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        max-width: 1200px;

        .logo,
        h2 {
            color: white;
        }

        .logo {
            font-size: 16pt;
            margin-top: auto;
            margin-bottom: auto;
            text-decoration: none;
            margin-left: 1rem;
        }
    }

    .profile-btns {
        display: flex;
        align-items: center;

        .login-btn,
        .register-btn {
            font-size: 12pt;
            margin: auto;
            cursor: pointer;
        }

        .login-btn:hover,
        .register-btn:hover {
            color: var(--grey-2);
        }
    }
}