.player-search-results {

	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		width: 80%;
		text-align: left;
		justify-content: left;
		text-shadow: 2px 2px 3px rgba(0,0,0,0.2);
	}		

	.lineups-table {
		font-size: 10pt;
		width: 100%;
		max-width: 1100px;

		.col-labels {
			border-bottom: None;

			.col-label {
				border-left: 1px solid gray;
				border-right: 1px solid gray;
			}
		}

		.table-header {
			background-color: #d3d3d3;
			border-top: none;
		}

		tbody {
			tr:hover {
				background-color: #d5d8dc;
			}
		}

	}
}