.contact-page {
    width: 100%;
	display: flex;
	flex-direction: column;
    align-items: center;
    
    .feedback-form {
        width: 90%;
        max-width: 900px;
        margin-top: 5vh;

        .input-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            label {
                font-weight: 600;
            }

            .form-control {
                border: 2px solid var(--grey-4);
                border-radius: 6px;
            }
            
            label {
                margin-bottom: 8px;
            }
        }
        .form-submit-btn {
            margin-top: 20px;
        }
    }
}