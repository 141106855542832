.lineups-page {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 70px;

	.lineup-wrapper {
		margin-top: 20px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-bottom: 2px solid var(--blue-grey-2);

		.filter-btn-wrapper {
			width: 100%;
			justify-content: left;
			display: flex;
			flex-direction: row;
			align-items: center;
			max-width: 1100px;

			.underline-btn {
				border: None;
				background: none;
				font-weight: 600;
				font-size: 14pt;
				color: black;
				padding: 5px 15px 5px 15px;
				border-bottom: 3px solid transparent;
				transition: all 0.2s;
			}
			.underline-btn:hover:not(.active) {
				border-bottom: 3px solid var(--grey-5);
			}
			.active {
				border-bottom: 3px solid var(--dark-blue);
			}
		}
	}
	.close-btn {
		color: red;
	}
	
	.loading-screen {
		height: 50vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.lineups-table {
	background: #ffffff;
	box-shadow: 0px 0px 5px 1px lightgrey;
	border: 1px solid lightgrey;
	width: 100%;
}

.lineups-table thead {
	background-color: white;
	top: 0;
	position: sticky;
	background-color: var(--grey-2);
	border: 1px solid var(--grey-4);
}

.lineups-table tbody tr:nth-child(even) {
	background-color: var(--grey-1);
}

.lineups-table tr:first-child {
	border-top: None;
}

.lineups-table tr:last-child {
	border-bottom: None;
}

.lineups-table th {
	// padding: 10px;
	// text-align: center;
}

.lineups-table td {
	// padding: 10px;
	// text-align: center;
	// font-size: 10pt;
}

.graphs-wrapper {
  display: flex;
  margin: 20px;
}

.close-btn, .submit-btn {
	border: None;
	background:  None;
}

.submit-btn {
	color: blue;
}


.filter-btn-active {
  border: 1px solid #0069ed;
  background-color: #0069ed;
  color: #ffffff;
  padding: 5px;
}

.graph-btn-wrapper {
	width: 100%;
	margin-bottom: 15px;
}

.graph-btn:hover {
	border-bottom: 3px solid #8c8c8c;
}

.graph-btn {
	color: #4f4f4f;
}

.graph-btn, .graph-btn-active {
	border: None;
	background: None;
	margin-right: 20px;
	font-size: 18pt;
	padding-bottom: 5px;
}

.graph-btn-active {
	border-bottom: 3px solid black;
	color: black;
}

// .icon {
// 	margin-bottom: 4px;
// 	margin-right: 3px;
// }

.fire-icon {
	color: orange;
}

.ice-icon {
	color: blue;
}

.lineupform-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.toggle-lineupform-btn {
  padding: 10px;
  background-color: #0069ed;
  border-radius: 3px;
  border: 1px solid lightgrey;
  color: white;
  margin: 10px;
  box-shadow: 0px 0px 3px 1px lightgrey;
}

.toggle-lineupform-btn:hover {
  color: #d3d3d3;
}

h3 {
	color: black;
}

.lineups-table-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 95%;
	max-width: 1100px;

	.lineups-table-wrapper-inner {
		width: 100%;
		max-width: 1100px;

		.table-header-wrapper {
			display: flex;
			width: 100%;
			flex-direction: column;
		}
	}
}
