.profile-page {
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80vh;
	width: 100vw;
    .profile-page-wrapper {
        display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #ffffff;
		border-radius: 10px;
		border: 1px solid lightgrey;
		padding: 40px 80px 40px 80px;
		box-shadow: 0px 0px 3px 1px lightgrey;
        .profile-page-inner {
            h1 {
                font-size: 18pt;
            }
            .info p {
                font-size: 12pt;
                text-align: left;
                margin-top: auto;
                margin-bottom: auto;
            }
            .info-line {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                button {
                    background: None;
                    border: None;
                    color: blue;
                }
            }
        }
    }

}