.graph-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.points-graph {
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 1px lightgray;
  width: 100%;
  border-radius: 6px;
}

.graph-label {
  text-align: left;
  padding: 10px 0px 0px 20px;
  font-size: 16pt;
}

.custom-tooltip {
  border-radius: 3px;
  background-color: #f2f2f2;
  padding: 10px;
}

.custom-tooltip span {
  display: inline-flex;
}

.custom-tooltip span p {
  margin-right: 5px;
  font-size: 11pt;
  font-weight: 500;
  margin-bottom: 0;
}

.graph-btn-wrapper {
  margin-top: 8px;
  margin-left: 20px;
}

h3 {
  color: #000000;
  font-size: 12pt;
  font-weight: 600;
}