.create-lineup-dialog {
    .content {
        background-color: var(--grey-3);
        max-height: 400px;
        padding: 0;

        .content-inner {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap-reverse;

            .games {
                flex: 1 200px;
                margin: 1rem;

                .games-inner {
                    margin-top: 0.5rem;
                }

                .game {
                    display: flex;
                    flex-direction: row;
                    background-color: white;
                    margin-bottom: 5px;
                    justify-content: space-between;
                    padding: 8px;
                    font-size: 0.8rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    box-shadow: var(--box-shadow-1);
                }
            }
            .user-lineups {
                margin: 1rem;
                flex: 1 300px;

                .user-lineups-inner {
                    margin-top: 0.5rem;
                }

                .lineup-options-btn {
                    text-decoration: None;
                    color: var(--btn-blue);
                    background: None;
                    border: None;
                    border-radius: 20px;
                    transition: all 0.2s;
                    font-size: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        margin: auto;
                        cursor: pointer;
                    }
                }

                tbody td, thead th {
                    padding: 0.4rem;
                    text-align: left;
                    font-size: 0.8rem;
                }
                
                .lineups-table {
                    margin: 0;
                    border-collapse: collapse;

                    thead {

                        th:first-child {
                            width: 30px;
                            margin: 0;
                        }
                        input {
                            margin: 0;
                        }
                        .toggle-select-btn {
                            border: 1px solid var(--grey-1);
                            padding: 3px;
                            border-radius: 5px;
                            background-color: var(--grey-3);
                        }
                        .toggle-select-btn:hover {
                            background-color: white;
                        }
                    }
                }
                .user-lineup {
                    background-color: white;
                    margin-bottom: 5px;
                    width: 100%;

                    .lineup-link {
                        color: var(--btn-blue);
                        text-decoration: none;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                }
            }
        }
    }
    .title {
        background-color: var(--dark-blue);
        
        .title-inner {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .close-btn {
                color: white;
                float: right;
                font-size: 18pt;
            }
            .close-btn:hover {
                cursor: pointer;
            }
        }
        p {
            color: white;
        }
        .title-upper {
            font-size: 16pt;
            text-align: left;
            font-weight: 500;

        }
        .title-lower {
            font-size: 13pt;
            text-align: left;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
    .actions {
        .confirm-delete-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 0.5rem;

            p {
                color: red;
            }
        }

        .cancel-btn {
            padding: 0.8rem 1.2rem 0.8rem 1.2rem;
            cursor: pointer;
            background: none;
            border: none;
            border-radius: 6px;
            color: var(--btn-blue);
            font-weight: 700;

        }
        .confirm-btn {
            background-color: red;
            color: white;
            border: none;
            padding: 0.8rem 1.2rem 0.8rem 1.2rem;
            border-radius: 6px;
            box-shadow: var(--box-shadow-1);
            cursor: pointer;
        }
        .create-btn {
            border: none;
            background-color: var(--btn-blue);
            color: white;
            padding: 0.8rem 1.2rem 0.8rem 1.2rem;
            border-radius: 6px;
            box-shadow: var(--box-shadow-1);
            cursor: pointer;
        }
        .export-btn {
            border: None;
            background: None;
            color: var(--btn-blue);
            display: flex;
            height: 100%;
            align-items: center;
            margin-right: 1.2rem;
            cursor: pointer;

            .export-icon {
                margin-left: 0.4rem;
                font-size: 1.1rem;
            }
        }
        .delete-btn {
            border: None;
            background: None;
            color: red;
            display: flex;
            height: 100%;
            align-items: center;
            margin-right: 0.9rem;
            margin-bottom: 0;
            cursor: pointer;

            .trash-icon {
                margin-left: 0.4rem;
                font-size: 1.1rem;
            }
        }
        .selected-counter {
            font-size: 0.9rem;
            margin-right: 1.2rem;
        }
        .download-btn {
            color: var(--btn-blue);
            display: flex;
            height: 100%;
            align-items: center;
            margin-right: 10px;
            font-size: 12pt;

            .download-icon {
                margin-left: 5px;
            }
        }
    }
}
