.compare-lineups-page {
    width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
    .header {
        background-color: white;
        box-shadow: var(--box-shadow-1);
        display: flex;
        justify-content: center;
        .header-inner {
            display: flex;
            flex-direction: column;
            padding: 5px;
            width: 80%;
            .header-title {
                font-size: 16pt;
            }
            .header-label {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
                .site {
                    font-size: 13pt;
                    font-weight: 400;
                    margin-right: 20px;
                }
                .date {
                    font-size: 13pt;
                    font-weight: 400;
                }
            }
        }
    }
    .main-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .lineups-wrapper-outer {
            width: 70%;
            .fav-players{
                display: flex;
                flex-direction: row;
                overflow-x: auto;
                margin-bottom: 20px;
                .fav-player {
                    display: flex;
                    flex-direction: row;
                    min-width: 160px;
                    width: 180px;
                    min-height: 70px;
                    height: 70px;
                    background-color: white;
                    border: 1px solid var(--grey-2);
                    margin: 5px;
                    img {
                        margin-top: 10px;
                        max-height: 60px;
                    }
                    .info {
                        padding: 6px;
                        p {
                            text-align: left;
                            margin: 0;
                        }
                        .exposure-display {
                            cursor: pointer;
                        }
                        .exposure-display:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .lineups-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                .header-inner {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
                button {
                    padding: 4px 12px 4px 12px;
                    border: None;
                    border-radius: 10px;
                    border: 1px solid black;
                    margin: 5px;
                }
                .new-lineup-btn {
                    color: white;
                    background-color: var(--btn-blue);
                    .icon {
                        font-size: 9pt;
                    }
                }
                .new-lineup-btn:hover {
                    background-color: var(--btn-blue-2);
                }
                .save-all-btn {
                    background-color: var(--grey-2);
                    cursor: default;
                }
                .save-all-btn:not(:not(.active)) {
                    background-color: var(--green);
                }
                .save-all-btn:not(:not(.active)):hover {
                    cursor: pointer;
                    background-color: var(--green-2);
                }
                .revert-all-btn {
                    background-color: var(--grey-2);
                    cursor: default;
                }
                .revert-all-btn:not(:not(.active)) {
                    background-color: red;
                    color: white;
                    .icon {
                        color: white;
                    }
                }
                .revert-all-btn:not(:not(.active)):hover {
                    cursor: pointer;
                    background-color: var(--red-2);
                }
            }
            h2 {
                margin-right: 20px;
                font-size: 14pt;
                margin-left: 15px;
            }
            .lineups-wrapper {
                display: flex;
                width: 100%;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                .delete-lineup-btn-wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    .delete-lineup-btn {
                        color: white;
                        background-color: red;
                        border: 1px solid black;
                        padding: 0px 15px 0px 15px;
                        border-radius: 5px;
                        font-size: 10pt;
                        width: fit-content;
                    }
                    .delete-lineup-btn:hover {
                        background-color: var(--red-2);
                    }
                }
            }
        }
        .draftables-wrapper-outer {
            width: 30%;
            .draftables-wrapper {
                width: 100%;
                h2 {
                    font-size: 14pt;
                }
                thead {
                    display: block;
                }
                thead tr {
                    justify-content: space-between;
                    th {
                        padding: 4px;
                        font-size: 10pt;
                    }
                }
                tbody {
                    max-height: 80vh;
                    overflow-y: auto;
                    display: block;
                    .player-name {
                        text-align: left;
                    }
                    .player-opp {
                        text-align: right;
                    }
                    tr {
                        // justify-content: space-between;
                        font-size: 9pt;
                    }
                    td {
                        padding: 8px;
                    }
                    .icon-wrapper:not(.active) {
                        background-color: var(--grey-1);
                        .swap-icon {
                            color: white;
                            height: fit-content;
                            font-size: 15pt;
                        }
                    }
                    .icon-wrapper:not(:not(.active)) {
                        background-color: var(--btn-blue);
                        cursor: pointer;
                        .swap-icon {
                            color: white;
                            height: fit-content;
                            font-size: 15pt;
                        }
                    }
                    .icon-wrapper:not(:not(.active)):hover {
                        background-color: var(--btn-blue-2);
                    }
                }
            }
        }
    }
}
