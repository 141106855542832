.empty-player {
    height: 80px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-bottom: 1px solid #d3d3d3;
    flex-direction: row;

    .playerImage {
        p {
            margin-left: 10px;
            margin-top: auto;
            margin-bottom: auto;
            text-align: center;
            font-weight: 600;
            font-size: 10pt;
        }
    }

    .player-info {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pos-label {
        height: 100%;
        margin: 0;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .add-player-btn, .cancel-add-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 0.8rem;
        font-weight: 600;
        cursor: pointer;

        .icon {
            margin-right: 0.4rem;
            display: flex;
            font-size: 0.9rem;
            padding: 2px;
            color: var(--btn-blue);
            user-select: None;
            border: 2px solid var(--btn-blue);
            border-radius: 50%;
        }
    }
}
