.lineups-table-wrapper {

	.lineups-table-wrapper-inner {

		.lineups-title {
			max-width: 1100px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		.pos-filter-wrapper-inner {
			margin-top: 10px;
		}

		.tag-filter-wrapper {
			display: flex;
			flex-direction: row;
			margin-top: 10px;
			margin-bottom: 10px;

			.tag-filter {
				border-radius: 6px;
				padding: 4px 8px 4px 8px;
				margin-right: 4px;
				background-color: var(--btn-blue);
				display: flex;
				flex-direction: row;
				border: None;
				color: white;
				font-size: 11pt;

				p {
					margin-bottom: 0;
				}

				.delete-icon {
					// display: None;
					color: white;
					margin-top: auto;
					margin-bottom: auto;
					margin-right: 5px;
					cursor: pointer;
				}
			}
		}

		.selectors {
			display: flex;
			flex-direction: row;
			margin-bottom: 0.5rem;

			select {
				margin-right: 0.5rem;
				padding: 0.4rem 0.8rem 0.4rem 0.8rem;
				font-size: 0.8rem;
				color: var(--grey-9);
				cursor: pointer;
				border: 1px solid var(--grey-5);
				border-radius: 4px;
				font-family: 'Nunito';
				font-weight: 600;
				background-color: white;
			}
		}

		.lineups-table {
			margin-top: 0px;
			box-shadow: var(--box-shadow-1);
			border: None;
			border-spacing: 0;
			font-size: 0.8rem;

			thead {
				position: sticky;
				top: 0;
				background-color: var(--grey-2);
				border: 1px solid var(--grey-4);

				.lineup-wrapper-header {
					justify-content: space-between;
					padding: 10px 10px 0px 10px;

					.lineup-options-btns {
						display: inline-flex;
						align-items: center;
						height: fit-content;
					}

					.download-btn {
						color: var(--btn-blue)
					}

					.selected-counter {
						font-size: 0.9rem;
						margin-left: 20px;
					}

					.lineup-options-btn {
						text-decoration: None;
						color: var(--btn-blue);
						background: None;
						border: None;
						border-radius: 20px;
						transition: all 0.2s;
						font-size: 14pt;
						height: 40px;
						width: 40px;
						display: flex;
						justify-content: center;
						align-items: center;

						.icon {
							margin: auto;
							cursor: pointer;
						}
					}

					.lineup-options-btn:hover {
						background-color: var(--grey-2);
					}

					.lineup-delete-btn {
						background-color: var(--red);
						text-decoration: None;
						color: white;
						border: 1px solid var(--grey-1);
						margin: 6px;
						padding: 7px 13px 7px 15px;
						border-radius: 20px;
						font-size: 11pt;
					}

					.lineup-export-btn {
						background-color: var(--btn-blue);
						text-decoration: None;
						color: white;
						border: 1px solid var(--grey-1);
						margin: 6px;
						padding: 7px 13px 7px 15px;
						border-radius: 20px;
						font-size: 11pt;
					}

					.lineup-export-btn:hover {
						background-color: var(--btn-blue-2);
					}

					.lineup-delete-btn:hover {
						background-color: var(--red-2);
					}
				}

				th {
					font-size: 10pt;
					text-align: left;
				}

				.options-row {
					padding-bottom: 0;
				}
			}

			.sortable-col {
				cursor: pointer;
				user-select: None;
			}

			tbody td,
			thead th {
				padding: 0.6rem;
			}

			tbody {
				border: 1px solid var(--grey-4);

				td {
					text-align: left;
				}

				.checkbox-wrapper {
					height: 100%;
					justify-content: center;
					align-items: center;
				}

				.checkbox {
					width: 100%;
					height: 100%;
					margin: auto;
				}

				.tags-wrapper {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					margin-top: -2px;


					.tag {
						border: 1px solid var(--btn-blue);
						border-radius: 6px;
						padding: 0px 3px 0px 3px;
						margin-right: 2px;
						margin-bottom: 0;
						white-space: nowrap;
						color: var(--btn-blue);
						display: block;
						margin-top: 2px;
					}

					.tag:hover {
						border: 1px solid transparent;
						background-color: var(--btn-blue);
						color: white;
						cursor: pointer;
					}
				}
			}
		}
	}

	.page-btn-wrapper {
		float: right;
		text-align: right;
		display: inline-flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 10px 10px 0px 0px;

		.page-btn {
			color: var(--btn-blue);
			border: 1px solid var(--blue-grey-4);
			margin-left: 5px;
			padding: 0px 7px 0px 7px;
			border-radius: 3px;
			cursor: pointer;
			user-select: None;
		}

		.page-btn-active {
			background-color: var(--btn-blue);
			color: white;
			margin-left: 5px;
			padding: 0px 7px 0px 7px;
			border-radius: 3px;
			border: 1px solid var(--btn-blue);
			user-select: None;
		}

		.page-arrow {
			color: var(--blue-grey-4);
			border: 1px solid var(--blue-grey-4);
			margin-left: 5px;
			padding: 0px 4px 0px 4px;
			border-radius: 3px;
			user-select: None;
		}

		select {
			color: var(--btn-blue);
			border: 1px solid var(--blue-grey-4);
			margin-left: 5px;
			padding: 0px 4px 0px 4px;
			border-radius: 3px;
			user-select: None;
			cursor: pointer;
		}

		.page-arrow-active {
			color: var(--btn-blue);
			border: 1px solid var(--blue-grey-4);
			margin-left: 5px;
			padding: 0px 4px 0px 4px;
			border-radius: 3px;
			user-select: None;
			cursor: pointer;
		}

		.page-label {
			margin-right: 20px;
		}
	}
}