.generate-lineup-dialog {
    .title {
        background-color: var(--dark-blue);
        color: white;

        .title-inner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h2 {
                font-size: 16pt;
                text-align: left;
                font-weight: 500;
            }

            .close-btn {
                cursor: pointer;
            }
        }
    }

    .content {
        background-color: var(--grey-2);

        hr {
            color: var(--grey-2);
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }

        .content-inner {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .generated-lineup-wrapper {
                display: flex;
                flex-direction: column;
                margin: 1rem;
                flex: 1 250px;
            
                .generated-lineup-wrapper-inner {
                    box-shadow: var(--box-shadow-1);
                    border-radius: 4px;
                    margin-top: 0.5rem;
                }

                .roller-wrapper {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                }

                .generated-lineup-details {
                    background-color: var(--grey-4);
                    margin-bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    p {
                        padding: 10px;
                        margin-bottom: 0;
                    }
                }
            }

            .options-wrapper {
                flex: 1 250px;
                margin: 1rem;
                display: flex;
                flex-direction: column;

                .options-wrapper-inner {
                    background-color: white;
                    box-shadow: var(--box-shadow-1);
                    margin-bottom: 0.5rem;
                    padding: 0.7rem;
                    border-radius: 4px;
                    margin-top: 0.5rem;
                }

                .section {
                    background-color: white;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0.5rem;

                    h4 {
                        font-weight: 500;
                        font-size: 0.9rem;
                        margin-right: 0.5rem;
                    }

                    select, input {
                        font-size: 0.7rem;
                    }
                    
                    select {
                        padding: 0.2rem;
                        border-radius: 4px;
                        margin-right: 0.4rem;
                    }

                    input {
                        margin-right: 0.2rem;
                    }

                    label {
                        font-size: 0.8rem;
                        margin-right: 5px;
                    }
                }

                .flex-position-buttons-wrapper {
                    label {
                        margin-right: 10px;
                    }
                }

                .generate-btn {
                    font-size: 0.8rem;
                    border: none;
                    background-color: var(--btn-blue);
                    color: white;
                    padding: 0.7rem 1rem 0.7rem 1rem;
                    border-radius: 6px;
                    box-shadow: var(--box-shadow-1);
                    cursor: pointer;
                }

            }
        }
    }

    .dialog-actions {
        background-color: var(--grey-2);

        .apply-btn {
            border: none;
            background-color: var(--btn-blue);
            color: white;
            padding: 0.8rem 1.2rem 0.8rem 1.2rem;
            font-size: 0.9rem;
            border-radius: 6px;
            box-shadow: var(--box-shadow-1);
            cursor: pointer;
        }
    }
}