.upcoming-players-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 6px 6px 6px 6px;

    .row {
        display: flex;
        flex-direction: row;
    }

    thead {
        display: flex;
        border-radius: 6px 6px 0px 0px;

        .name-col {
            flex: 5;
        }
    }

    thead th,
    tbody td {
        padding: 0.8rem 0.2rem 0.8rem 0.2rem;
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 3;
    }

    thead th {
        font-weight: 600;
    }

    .icon-col, .pos-col {
        flex: 1;
    }

    tbody {
        // .favorite-icon {filter:drop-shadow(0px 0px 1px var(--green-2))}
        max-height: 70vh;
        overflow-y: auto;
        display: block;
        border-radius: 0px 0px 6px 6px;
        border-collapse: separate;

        .bold {
            font-weight: 800;
        }

        .name-col {
            text-align: left;
            justify-content: flex-start;
            padding-left: 10px;
            font-weight: 600;
            flex: 5;
            color: blue;
            cursor: pointer;
        }

        tr {
            display: flex;
            flex-direction: row;
        }

        .add-icon {
            color: var(--btn-blue);
            border: 2px solid var(--btn-blue);
            border-radius: 50%;
            cursor: pointer;
        }

        .add-icon.inactive {
            color: var(--grey-5);
            border: 2px solid var(--grey-5);
            cursor: default;
        }

        .favorite-icon {
            border: 1px solid var(--green-2);
            border-radius: 50%;
            color: var(--green);
            cursor: pointer;
            font-size: 1rem;
        }

        .undraftables-icon {
            color: var(--red);
            border: 1px solid var(--red);
            border-radius: 50%;
            font-size: 1rem;
            cursor: pointer;
        }

        .add-icon,
        .no-add-icon,
        .favorite-icon,
        .no-favorite-icon,
        .undraftables-icon,
        .no-undraftables-icon {
            // color: white;
            padding: 0.1rem;
        }
    }

    .empty-players-row {
        .add-players-btn {
            background: None;
            border: None;
            color: var(--btn-blue);
            font-weight: 600;
        }
    }
}

.upcoming-players-table.small {
    .hide-small {
        display: none;
    }
}

