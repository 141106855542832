@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');


* {
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--white-1);
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
}

@media (min-width: 600px) {
  html {
    font-size: 12pt;
  }
}

@media (max-width: 599px) {
  html {
    font-size: 11pt;
  }
}

:root {
  --btn-blue: #0069ed;
  --btn-blue-2: rgba(0, 105, 237, 0.8);
  --btn-blue-3: rgba(0, 105, 237, 0.5);
  --grey-1: #5d6f7a;
  --grey-2: lightgrey;
  --grey-3: #f2f2f2;
  --roboto: 'Nunito', sans-serif;
  --inter: 'Inter', serif;
  --source-sans: 'Source Sans Pro', sans-serif;
  --box-shadow-1: 0px 0px 3px 1px lightgray;
  --box-shadow-2: 0px 0px 3px 1px #e0e0e0;
  --black-1: #141414;
  --black-2: #161B22;
  --space-blue: #2A2F44;
  --slate: #788A95;
  --white-1: #f7fafc;
  --dark-blue: #202033;
  --dark-blue-2: #3d3d54;
  --dark-red: #282119;
  --dark-other: #15233A;
  --green: #00992e;
  --green-2: #41a35e;
  --green-3: #00992e70;
  --green-4: #41a35e70;
  --green-5: #ffdb2b;
  --green-6: #ffdb2b70;
  --red: #fa2525;
  --red-2: #ff4a4a;
  --red-3: #fa252590;
  --red-4: #ff4a4a90;

  --black: #000000;
  --dark-grey: #121212;

  --grey-1: #fafafa;
  --grey-2: #f5f5f5;
  --grey-3: #eeeeee;
  --grey-4: #e0e0e0;
  --grey-5: #bdbdbd;
  --grey-6: #9e9e9e;
  --grey-7: #757575;
  --grey-8: #616161;
  --grey-9: #424242;
  --grey-10: #212121;

  --blue-grey-1: #f0f4f8;
  --blue-grey-2: #d9e2ec;
  --blue-grey-3: #bcccdc;
  --blue-grey-4: #9fb3c8;
  --blue-grey-5: #829ab1;
  --blue-grey-6: #627d98;
  --blue-grey-7: #486581;
  --blue-grey-8: #334e68;
  --blue-grey-9: #243b53;
  --blue-grey-10: #102a43;

  --cool-grey-1: #f5f7fa;
  --cool-grey-2: #e4e7eb;
  --cool-grey-3: #cbd2d9;
  --cool-grey-4: #9aa5b1;
  --cool-grey-5: #7b9794;
  --cool-grey-6: #616e7c;
  --cool-grey-7: #52606d;
  --cool-grey-8: #3e4c59;
  --cool-grey-9: #323f4b;
  --cool-grey-10: #1f2933;

}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white-1);
}

.page-wrapper-inner {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 70vh;
}

.hidden {
  display: None;
}

.form-control {
  border: None;
}

.ring {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.players-wrapper {
  margin-top: 50px;
}

.header {
  background-color: #ffffff;
}

h1 {
  margin: 20px 20px 10px 5px;
  font-weight: 600;
  font-size: 1.5rem;
  width: 100%;
  text-align: left;
}

h3 {
  font-size: 1.1rem;
}

.filter-btn-wrapper {
  .filter-btn-active {
    border-left: 1px solid #0069ed;
    border-top: 1px solid #0069ed;
    border-bottom: 1px solid #0069ed;
    background-color: #0069ed;
    color: #ffffff;
  }
  .filter-btn {
    border-left: 1px solid var(--grey-5);
    border-top: 1px solid var(--grey-5);
    border-bottom: 1px solid var(--grey-5);
    color: var(--grey-9);
    background-color: #ffffff;
    font-weight: 600;
  }
  .filter-btn,
  .filter-btn-active {
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 0.8rem;
    cursor: pointer;
    border-right: None;
    font-family: 'Nunito';
  }
  :first-child {
    border-radius: 4px 0px 0px 4px;
  }
  :last-child {
    border-right: 1px solid var(--grey-5);
    border-radius: 0px 4px 4px 0px;
  }
}

.delete-lineup-link {
  text-decoration: none;
  color: #991728;
}

.players {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.search-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player-search-form {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  width: 100%;
  max-width: 500px;
}

.pos-label {
  color: black;
  background-color: #d3d3d3;
  padding: 3px;
  margin: 3px;
  text-transform: uppercase;
}

.players {
  box-shadow: 0px 0px 3px 1px lightgray;
}

.players {
  display: flex;
  flex-direction: column;
  align-content: center;
  max-height: 70vh;
  overflow: auto;
}

.delete-btn,
.add-btn {
  text-align: right;
  margin-bottom: -7px;
}

.search-btn {
  display: block;
  background: #0069ed;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  text-decoration: none !important;
}

.info {
  color: black;
  text-align: center;
}

.player-section {
  display: block;
}

.add-player-btn {
  border: None;
  background: None;
  padding: 5px;
  margin: 3px;
  color: #0069ed;
  text-transform: uppercase;
}

.new-lineup-btn {
  background: None;
  border: None;
}

.lineups-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form-submit-btn {
  border: None;
  background-color: #2d78fa;
  color: white;
  border-radius: 5px;
  padding: 7px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.lineup-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}

.view-lineup-btn {
  text-decoration: None;
  text-align: center;
  color: #0069ed;
}

.view-lineup-btn:hover {
  cursor: pointer;
}

hr {
  background: #9e9e9e;
  height: 1px;
  border: None;
  margin: 5px;
}

.website-title {
  font-family: 'Inconsolata';
  font-weight: 600;
}

button {
  font-family: 'Nunito';
}