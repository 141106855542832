.importLineupsDialog {
    .title {
        background-color: var(--blue-grey-8);

        .title-inner {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .close-btn {
                color: white;
                float: right;
                font-size: 18pt;
                cursor: pointer;
            }
        }
        p {
            color: white;
            font-size: 14pt;
        }
        .title-upper {
            font-size: 16pt;
            text-align: left;
            font-weight: 500;

        }
        .title-lower {
            font-size: 12pt;
            text-align: left;
            font-weight: 300;
        }
    }
    .content {
        
        button {
            border: 1px solid var(--btn-blue);
            color: var(--btn-blue);
            background: None;
            padding: 5px 15px 5px 15px;
            margin-right: 10px;
            border-radius: 25px;
            height: fit-content;
        }
        .selected {
            outline: None;
            color: white;
            background-color: var(--btn-blue);
        }
        p {
            font-size: 12pt;
        }
    }
}