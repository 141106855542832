.lineup {
    box-shadow: var(--box-shadow-1);
    height: fit-content;
    border-radius: 8px;
    margin: auto;
    margin-bottom: 1rem;

    .player:first-child {
        border-radius: 8px 8px 0px 0px;
    }

    .player:last-child, .empty-player:last-child {
        border-radius: 0px 0px 8px 8px;
    }

    .selected {
        outline: 2px solid var(--grey-5);
        border-bottom: 1px solid transparent;
    }
}