.footer {
	padding: 20px;
	margin-top: 50px;
	background-color: var(--dark-blue);

	.footer-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h3 {
			font-size: 14pt;
			color: white;
		}

		.footer-links {
			display: flex;
			flex-direction: row;

			.player-link {
				margin: 10px;
				color: var(--cool-grey-2)
			}
			.player-link:hover {
				text-decoration: underline;
			}
		}
	}
}
