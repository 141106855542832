.history-page {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.header {
		width: 90%;
		max-width: 1100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: inherit;
		margin-bottom: 10px;
		
		.header-inner {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		.selectors {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			
			select {
				margin-right: 0.5rem;
				padding: 0.4rem 0.8rem 0.4rem 0.8rem;
				font-size: 0.8rem;
				border: 1px solid var(--grey-5);
				border-radius: 4px;
				color: var(--grey-9);
				background-color: white;
			}
			.week-select {
				min-width: 120px;
			}
			.year-select {
				min-width: 100px;
			}
		}
	}
	.loading-screen {
		height: 50vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
