.lower-nav {
	width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--cool-grey-9);

    .lower-nav-inner {
        width: 95%;
        max-width: 1200px;
        display: flex;
        justify-content: center;

        .lower-nav-links {
            width: 100%;
            display: flex;
            flex-direction: row;
            list-style-type: none;

            .nav-link {
                font-size: 12pt;
                color: white;
                padding: 0.4rem 1rem 0.4rem 1rem;
            }
        }
    }
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%
  }
