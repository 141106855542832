.lineupPlayer {

    background-color: white;
    border-bottom: 1px solid #d3d3d3;
    display: flex;
    flex-direction: row;
    height: 80px;

    .playerImage {
        align-self: center;
        display: flex;
        flex-direction: column;
        padding-top: 0.2rem;
        flex: 1;

        p {
            font-size: 0.8rem;
            margin-top: auto;
            margin-bottom: auto;
            text-align: center;
            font-weight: 600;
        }
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: None;
        flex: 1;

        .add-icon {
            margin-top: 0.2rem;
            font-size: 1.3rem;
            color: var(--btn-blue);
            user-select: None;
            border: 2px solid var(--btn-blue);
            border-radius: 50%;
        }

        .delete-icon {
            margin-top: 0.2rem;
            font-size: 1.1rem;
            padding: 2px;
            color: red;
            user-select: None;
            border: 2px solid red;
            border-radius: 50%;
        }
    }

    .name-and-info {
        display: flex;
        flex-direction: column;
        flex: 4;
    }

    .playerInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 60%;
    }

    .infoBlock {
        display: flex;
        justify-content: center;

        p {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            align-items: center;

            .value {
                font-weight: 500;
                font-size: 0.8rem;
            }

            .label {
                font-size: 0.7rem;
                color: gray;
            }
        }
    }

    .playerName {
        display: flex;
        height: 40%;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            color: blue;
            font-weight: 600;
            font-family: var(--nunito);
        }
    }
}

// .lineup.small .lineupPlayer {

//     .playerImage p {
//         font-size: 9pt;
//     }

//     .playerName {
//         display: flex;
//         height: 40%;
//         cursor: pointer;

//         p {
//             font-size: 11pt;
//             height: 100%;
//             display: flex;
//             flex-direction: column;
//             justify-content: flex-end;
//             color: blue;
//             font-weight: 600;
//             font-family: var(--nunito);
//         }
//     }
//     .infoBlock {
//         display: flex;
//         justify-content: center;

//         p {
//             display: flex;
//             flex-direction: column;
//             height: 100%;
//             justify-content: center;
//             align-items: center;

//             .value {
//                 font-weight: 500;
//                 font-size: 8pt;
//             }

//             .label {
//                 font-size: 7pt;
//                 color: gray;
//             }
//         }
//     }
// }