.upcoming-page {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.players-outer {
		width: 95%;
		max-width: 800px;

		.filters-outer {
			display: flex;
			flex-direction: row;
			margin-bottom: 0.4rem;
			flex-wrap: wrap-reverse;
			justify-content: space-between;
			width: 100%;

			.pos-filter-wrapper {
				display: flex;
				width: fit-content;
				max-width: 1100px;
				justify-content: left;
				align-items: center;
				margin-bottom: 0.2rem;
				margin-top: 0.4rem;
			}

			.player-search {
				display: flex;
				flex-direction: row;
				border-radius: 3px;
				margin-bottom: 0.2rem;
				margin-top: 0.2rem;
				padding: 0.5rem 1rem 0.5rem 1rem;
				border: 1px solid var(--grey-5);
				outline: none;
				border-radius: 6px;
				background-color: white;
				align-items: center;

				.search-input {
					border: none;
					padding-left: 0.5rem;
					outline: none;
					background: none;
				}
			}
		}

		.players-outer-header {

			.new-lineup-btn {
				text-decoration: None;
				background-color: var(--btn-blue);
				border: None;
				color: white;
				padding: 0.6rem 1.2rem 0.6rem 1.2rem;
				border-radius: 4px;
				transition: all 0.3s;
				font-size: 0.9rem;
				cursor: pointer;
				display: flex;
				align-items: center;
				margin-top: 0.5rem;
				font-weight: 600;

				.icon {
					margin-left: 0.5rem;
				}
			}

			.new-lineup-btn:hover {
				background-color: var(--btn-blue-2);
			}

			h2 {
				text-align: left;
				font-weight: 600;
				font-size: 18pt;
				margin-top: auto;
				margin-bottom: auto;
				font-size: 1.5rem;

				.slate-title {
					color: var(--grey-10);
					font-weight: 400;
				}
			}

			p {
				margin-bottom: 0;
			}
		}

		.players-inner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.lineups-table {
				font-size: 0.8rem;
				width: 100%;
				max-width: 1100px;
				border-spacing: 0;

				thead th,
				tbody td {
					padding: 0.6rem;
					text-align: center;
				}

				thead {
					position: sticky;
					top: 0;
				}

				.col-labels {
					border-bottom: None;

					.col-label {
						border-left: 1px solid gray;
						border-right: 1px solid gray;
					}
				}
				.player-col {
					text-align: left;
				}

				.table-header {
					background-color: #d3d3d3;
					border-top: none;
				}

				tbody {
					tr:hover {
						background-color: var(--cool-grey-2);
					}

					tr {
						transition: all 0.1s;
						font-weight: 400;

						.bold {
							font-weight: 800;
						}
					}
				}
			}
		}
	}

	.site-filter-wrapper {
		margin-top: 0.5rem;
		width: 100%;
		justify-content: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-bottom: 2px solid var(--blue-grey-2);

		.site-filter-wrapper-inner {
			max-width: 1100px;
			display: flex;
			flex-direction: row;
			justify-content: left;
			width: 100%;
		}

		.underline-btn {
			border: None;
			background: None;
			padding: 0.7rem 1rem 0.7rem 1rem;
			font-weight: 500;
			color: black;
			font-size: 1.2rem;
			border-bottom: 3px solid transparent;
			transition: all 0.2s;
		}

		.underline-btn:hover:not(.active) {
			border-bottom: 3px solid var(--grey-5);
			cursor: pointer;
		}

		.active {
			border-bottom: 3px solid var(--dark-blue);
		}
	}

	.slatesWrapper-outer {
		width: 95%;
		max-width: 1100px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		h3 {
			width: 100%;
			text-align: left;
			margin-left: 0.6rem;
			margin-top: 0.6rem;
			font-weight: 600;
		}

		.search-btn {
			width: fit-content;
		}

		.slatesWrapper {
			display: flex;
			flex-direction: row;
			margin: 0 1rem 0.5rem 1rem;
			width: 100%;
			max-width: 1100px;
			align-items: left;
			overflow-x: auto;
			padding: 0 0.5rem 0.5rem 0.5rem;

			.slate {
				margin: 0.4rem;
				padding: 0.6rem;
				min-width: 120px;
				width: 20vw;
				max-width: 200px;
				border-radius: 6px;
				background-color: white;
				cursor: pointer;
				text-align: left;
				text-decoration: none;
				font-weight: 600;
				overflow: visible;
				box-shadow: var(--box-shadow-1);
				border: 2px solid transparent;
				transition: all 0.2s;
				line-height: 1.5rem;

				p:first-child {
					font-weight: 600;
					font-size: 0.9rem;
				}

				p:nth-child(2) {
					color: var(--grey-9);
					font-size: 0.8rem;
				}

				.link-btn {
					width: fit-content;
					transition: all 0.2s;
					font-size: 0.8rem;
				}
			}

			.slate:hover:not(.active) {
				border: 2px solid var(--grey-4);
				text-decoration: None;
			}

			.active {
				border: 2px solid var(--btn-blue);
			}

			.link-btn {
				color: var(--btn-blue);
				display: flex;
				align-items: center;
			}

			.link-btn:hover {
				color: var(--dark-blue);
			}
		}
	}

	.loading-screen {
		height: 50vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}