.players-results {
	width: 90%;
	max-width: 1100px;
	display: flex;
	flex-direction: column;

	.filters-outer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap-reverse;
	}

	.filter-btn-wrapper {
		margin-bottom: 0.5rem;
	}

	.player-search {
		display: flex;
		flex-direction: row;
		margin-bottom: 0.5rem;
		padding: 0.5rem 1rem 0.5rem 1rem;
		border: 1px solid var(--grey-5);
		outline: none;
		border-radius: 6px;
		background-color: white;
		align-items: center;

		.search-input {
			border: none;
			padding-left: 0.5rem;
			outline: none;
			background: none;
		}
	}

	.load-more-btn {
		border: None;
		background: None;
		color: var(--btn-blue);
		font-size: 14pt;
		margin-top: 10px;
		margin-bottom: 30px;
		font-weight: 600;
	}

	.players-table {
		font-size: 0.8rem;
		width: 100%;
		max-width: 1100px;
		border-collapse: collapse;

		thead {
			position: sticky;
			top: 0;
			background-color: var(--grey-2);
			border: 1px solid var(--grey-4);
		}

		.col-labels {
			.col-label {
				box-shadow: inset 1px 0px 0 var(--grey-5);

			}
		}
		tbody td, thead th {
			padding: 0.5rem;
			font-size: 0.8rem;
		}

		tbody {
			tr:hover {
				background-color: var(--cool-grey-2);
			}
		}
	}
}

@media (max-width: 750px) {
	.players-table {
		display: block;
		overflow-x: auto;
		border-spacing: 0;

		td {
			font-weight: 400;
			padding: 0.3rem;
		}
	}
}