.lineup-mini {
    width: 300px;
    box-shadow: var(--box-shadow-1);
    border-top: 1px solid var(--grey-2);
    margin: 5px;
    .lineup-mini-header {
        background-color: white;
        border-left: 1px solid var(--grey-2);
        border-right: 1px solid var(--grey-2);
        border-bottom: 1px solid var(--grey-2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 60px;
        .header-upper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 6px;
            p {
                margin-top: auto;
                margin-bottom: auto;
                text-align: left;
                margin-left: 5px;
                font-size: 10pt;
                font-weight: 500;
            }
        }
        .header-lower {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 0px 6px 4px 6px;
            .edit-btn {
                border: none;
                background: inherit;
                color: var(--btn-blue);
                padding-left: 10px;
                border-radius: 10px 0px 0px 10px;
            }
            .edit-btn:hover {
                text-decoration: underline;
                text-decoration-color: var(--btn-blue);
            }
            .save-btn {
                border: None;
                background-color: var(--grey-2);
                height: fit-content;
                padding: 0px 15px 0px 15px;
                border: 1px solid black;
                border-radius: 5px;
                cursor: default;
            }
            .save-btn:not(:not(.active)) {
                background-color: var(--green);
            }
            .save-btn:not(:not(.active)):hover {
                background-color: var(--green-2);
                cursor: pointer;
            }
            .revert-btn {
                border: None;
                background-color: inherit;
                color: var(--grey-1);
                cursor: default;
            }
            .revert-btn:not(:not(.active)) {
                color: red;
            }
            .revert-btn:not(:not(.active)):hover {
                cursor: pointer;
                text-decoration: underline;
                text-decoration-color: red;
            }
            button {
                font-size: 10pt;
            }
        }
    }
}