.mobile-nav {
    width: 100%;
    * {
        margin: 0;
        box-sizing: 0;
    }
    .logo {
        height: 1.2rem;
        cursor: pointer;
        padding: 0;
    }
    nav {
        height: 1.5em;
        background-color: var(--dark-blue);
        display: flex;
        justify-content: space-between;
        padding: 1em;
    }
    .burger-menu {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        cursor: pointer;
    }
    .burger-bar {
        width: 1.5em;
        height: 0.25em;
        background-color: white;
        border-radius: 0.5em;
    }
    .menu {
        width: 100%;
        background-color: white;
        position: absolute;
        z-index: 1000;
        // box-shadow: var(--box-shadow-1);
    }
    .hidden {
        display: none;
    }
    .visible {
        display: inherit;
    }
    .burger-bar.clicked:nth-child(1) {
        transform: rotate(45deg) translate(0.5em, 0.375em);
        transition: ease-out 0.2s;
    }
    .burger-bar.clicked:nth-child(2) {
        transform: scale(0.0);
        transition: ease-out 0.2s;
    }
    .burger-bar.clicked:nth-child(3) {
        transform: rotate(135deg) translate(-0.5em, 0.375em);
        transition: ease-out 0.2s;
    }
    .burger-bar.unclicked {
        transform: rotate(0) translate(0);
        transition: ease-out 0.2s;
    }
    .mobile-nav-link {
        color: black;
        padding: 1.1em;
        font-weight: 700;
        border-bottom: 1px solid var(--grey-4);
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
    }
}

@media (min-width: 500px) {
	.mobile-nav {
		display: None;
	}
}