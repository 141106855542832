.login-page, .register-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	background-color: var(--white-1);

	.form-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: calc(40px + 4vh);
		
		h1 {
			width: 100%;
			text-align: center;
		}

		label {
			font-size: 0.9rem;
			font-weight: 600;
			margin-top: 1.5rem;
			margin-bottom: 0.5rem;
		}
	}

	.login-form, .register-form {
		display: flex;
		flex-direction: column;
	}

	input {
		outline: 2px solid #e8e8e8;
		padding: 0.7rem;
		font-size: 0.9rem;
	}

	input:focus {
		box-shadow: none !important;
		outline: 2px solid #9e9e9e !important;
	}

	.form-submit-btn {
		margin-top: 1.5rem;
		width: 100%;
		border-radius: 4px;
		font-weight: 500;
		padding-top: 0.7rem;
		padding-bottom: 0.7rem;
		font-size: 1rem;
		cursor: pointer;
	}

	.form-control {
		width: 350px;
	}

	.register-page-link-wrapper, .login-page-link-wrapper {
		margin-top: 40px;
		width: 300px;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	}

	.register-page-link, .login-page-link {
		margin-left: 20px;
		text-decoration: none;
		color: blue;
	}

	.alert {
		color: red;
		font-size: 11pt;
		text-align: center;
		margin: 0;
		padding: 0;
	}

	.show-password {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 1.5rem;
	}

	.show-password p {
		margin: 0;
		font-size: 0.9rem;
	}

	.register-page-link-wrapper p, .login-page-link-wrapper p {
		font-size: 1rem;
		.register-page-link {
			font-weight: 600;
		}
	}

	.show-password input {
		margin:  0px;
		margin-right: 10px;
	}
}