.player-dialog {
	.content, .section, .player-dialog {
		max-height: 400px;
		width: 100%;
	}
	.title {
		background-color: var(--dark-blue);
		padding: 0px;
		.title-inner {
			color: white;
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;
			padding: 0px;
			.player-info-inner {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
				.game-info {
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: left;
					margin-right: 20px;
					width: 50%;
					.details-link {
						margin-top: 10px;
						font-size: 11pt;
						color: var(--grey-3);
					}
				}
			}
			.close-btn {
				margin: 15px;
				color: white;
				cursor: pointer;
			}
			p {
				color: white;
			}
			.player-info {
				padding: 20px;
				padding-bottom: 0;
				width: 100%;

				h3 {
					color: white;
					font-size: 16pt;
					font-weight: 500;
					.name {
						font-weight: 700;
						font-size: 20pt;
					}
				}
				p {
					font-size: 12pt;
					margin: 0;
				}
				.draft-info {
					display: flex;
					flex-direction: row;
					width: 50%;
					.info-block {
						// background-color: darkslategray;
						padding: 5px;
						margin: 5px;
						border-radius: 10px;
						width: 80px;
						:first-child {
							font-size: 14pt;
						}
						:nth-child(2) {
							font-size: 10pt;
							color: var(--grey-3)

						}
						p {
						}
					}
				}
			}
		}
	}

	.content {
		.lineups-table {
			.col-labels {
				th {
					padding: 4px;
				}
			}
			th {
				font-size: 10pt;
				height: 20px;
				padding: 8px;
			}
			tbody {
				td {
					font-size: 10pt;
				}
				tr {
					background-color: white;
				}
			}
		}

	}
}