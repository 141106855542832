.green {
    background-color: var(--green-6);
    border: 2px solid var(--green-5);
}
.red {
    background-color: var(--red-4);
    border: 1px solid var(--red-2);
}
.blue {
    background-color: var(--btn-blue-3);
    border: 1px solid var(--btn-blue-2);
}
.alert-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s;
    width: 100%;
    p {
        display: flex;
        font-size: 12pt;
        margin: 0;
        padding: 10px;
        font-weight: 400;
    }
    .close-btn {
        color: black;
        font-size: 16pt;
        margin-right: 15px;
    }
    .close-btn {
        cursor: pointer;
    }
}