.default-table {
    .lineups-table thead {
        background-color: lightgrey;
    }
    
    .lineups-table tr {
        border: 1px solid lightgrey;
    }
    
    .lineups-table tr:nth-child(even) {
        background-color: var(--grey-3);
    }
    
    .lineups-table tr:first-child {
        border-top: None;
    }
    
    .lineups-table tr:last-child {
        border-bottom: None;
    }
    
    .lineups-table th {
        padding: 10px;
        text-align: center;
    }
    
    .lineups-table td {
        padding: 10px;
        text-align: center;
        font-size: 10pt;
    }

    .lineups-table {
        background: #ffffff;
        box-shadow: 0px 0px 10px 1px lightgrey;
        width: 100%;
    }


}